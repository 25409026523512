import { Heading } from "@chakra-ui/react";
import React from "react";

const SectionTitle = ({ children, style }) => {
  return (
    <>
      <Heading sx={style} size={{ base: "lg", md: "xl" }}>
        {children}
      </Heading>
    </>
  );
};

export default SectionTitle;
