import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  Stack,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import axios from "axios";
import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { DataStoreContext } from "../../../../Context/DataProvider";
import { getPrintInfo } from "../../../../utilities/getPrintInfo";
import { getPrintingValue } from "../../../../utilities/getPrintingValue";
import { sendUpdatedStockInv } from "../../../../utilities/sendUpdatedStockInv";

export const InventoryStock = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const { host, limit, page, selectedPrintedInventory } =
    useContext(DataStoreContext);
  const { data: inventory = [] } = useQuery({
    queryKey: ["inventory"],
    queryFn: async () => {
      try {
        const { data } = await axios.get(
          `${host}/inventory?page=${page}&limit=${limit}`
        );

        return data.data;
      } catch (error) {
        console.log(error);
      }
    },
  });
  const printingInventoryValues = getPrintingValue(
    search,
    selectedPrintedInventory
  );
  const {
    register,
    handleSubmit,
    reset,
    // formState: { errors },
  } = useForm();
  const onSubmit = (stocksData) => {
    // send updated stock data on database
    sendUpdatedStockInv(host, printingInventoryValues, stocksData);
    // getting print information
    const printInfo = getPrintInfo(printingInventoryValues, stocksData);
    // set print information on session storage
    sessionStorage.setItem("printInfo", JSON.stringify(printInfo));
    if (printInfo) {
      navigate(`/print-inventory/print-page${search}`);
    }
    reset();
  };

  useEffect(() => {
    if (selectedPrintedInventory.length === 0) {
      return navigate("/");
    }

    const beforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";
      return "Changes you made may not be saved.";
    };
    window.addEventListener("beforeunload", beforeUnload);

    return () => window.removeEventListener("beforeunload", beforeUnload);
  }, [selectedPrintedInventory]);

  return (
    <Flex align={"center"} justify={"center"} bg={"gray.50"}>
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"3xl"}>Inventory | Print Page Stock</Heading>
        </Stack>
        <Box
          rounded={"lg"}
          bg={"white"}
          borderWidth={1}
          borderColor={"gray.300"}
          p={8}
        >
          <Stack as={"form"} spacing={4} onSubmit={handleSubmit(onSubmit)}>
            <FormControl id="stock">
              <FormLabel fontSize={"lg"}>Stock</FormLabel>
              {printingInventoryValues?.map((item, i) => (
                <VStack
                  key={i}
                  alignItems={"start"}
                  spacing={0}
                  textColor={"gray.700"}
                >
                  <Text>Enter stock for {item?.name}</Text>
                  <Flex align={"center"}>
                    <Input
                      placeholder={`${item?.name}`}
                      type="number"
                      my={2}
                      borderEnd={0}
                      roundedBottomRight={0}
                      roundedTopRight={0}
                      {...register(`${item?.id}`, {
                        required: true,
                        valueAsNumber: true,
                      })}
                    />
                    <Select
                      roundedBottomLeft={0}
                      roundedTopLeft={0}
                      placeholder="Select type"
                      {...register(`productNo-${item?.id}-type`, {
                        required: true,
                      })}
                    >
                      {["piece", "bottles", "packets", "boxes"]?.map(
                        (item, i) => (
                          <option
                            key={i}
                            value={item}
                            style={{ textTransform: "capitalize" }}
                          >
                            {item}
                          </option>
                        )
                      )}
                    </Select>
                  </Flex>
                </VStack>
              ))}
            </FormControl>
            <FormControl id="stock">
              <FormLabel fontSize={"lg"}>Vehicle No.</FormLabel>
              <Input
                placeholder={`Enter vehicle number`}
                type="text"
                my={2}
                {...register(`vehicleNo`)}
              />
            </FormControl>

            <Textarea placeholder="Remarks" {...register(`remarks`)} />

            <Stack spacing={10}>
              <Button
                bg={"blue.400"}
                color={"white"}
                _hover={{
                  bg: "blue.500",
                }}
                type="submit"
              >
                Submit
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
};
