import axios from "axios";

export const storeData = async (host, storeData, path, successText) => {
  try {
    const { data } = await axios.post(`${host}/${path}`, storeData);
    if (data.name) {
      alert(successText);
    }
  } catch (error) {
    console.log(error);
  }
};
