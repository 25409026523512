export const getPrintingValue = (search, inventory) => {
  const idMatchregex = /id=(\d+)/g;
  const matchesId = [...search.matchAll(idMatchregex)];
  const printInventoryId =
    matchesId.length > 0 && matchesId?.map((match) => match[1]);

  const printingInventoryValues = inventory?.filter((invItem) => {
    return printInventoryId?.map((id) => Number(id)).includes(invItem.id);
  });
  return printingInventoryValues;
};
