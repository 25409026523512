import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  GridItem,
  Icon,
  Input,
  Select,
  SimpleGrid,
  Stack,
  VisuallyHidden,
  chakra,
} from "@chakra-ui/react";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { v4 } from "uuid";
import SectionTitle from "../../../Components/SectionTitle";
import { DataStoreContext } from "../../../Context/DataProvider";
import { storage } from "../../../firebaseConfig";
import { storeData } from "../../../utilities/storeData";
import { updatableInventory } from "../../../utilities/updatableInventory";
import { updateData } from "../../../utilities/updateData";
export const AddInventory = ({ singleInventory, setSingleInventory }) => {
  const { verifiedUser, host, godwonData, categories } =
    useContext(DataStoreContext);
  const pathname = useLocation().pathname;
  const [imgPath, setImgPath] = useState(null);
  const {
    register,
    handleSubmit,
    reset,
    // formState: { errors },
  } = useForm();
  const onSubmit = async (inventory) => {
    // Closing all access
    if (verifiedUser !== "admin") {
      return alert("Access Denied!");
    }
    // store data on database
    if (pathname !== "/") {
      const imgRef = ref(storage, `images/${imgPath.name + v4()}`);
      uploadBytes(imgRef, imgPath.imgFile).then((res) => {
        getDownloadURL(res.ref).then((url) => {
          if (url) {
            inventory.inventoryImage = url;
            storeData(
              host,
              inventory,
              "inventory",
              "Inventory successfully added"
            );
          }
        });
      });
    } else {
      const updatedInventory = updatableInventory(inventory, singleInventory);
      updateData(
        host,
        updatedInventory,
        "inventory",
        singleInventory?.id,
        "Inventory updated successfully"
      );
      updatableInventory({}, {});
      setSingleInventory(null);
    }
    reset();
  };
  const handleImgPath = (e) => {
    e.preventDefault();
    const imgFile = e.target.files[0];
    setImgPath({ name: imgFile.name, imgFile });
  };
  return (
    <Box>
      {pathname === "/add-inventory" && (
        <SectionTitle>Add Inventory</SectionTitle>
      )}
      <Box mt={`${pathname === "/add-inventory" && 5}`}>
        <>
          <GridItem mt={[5, null, 0]} colSpan={{ md: 2 }}>
            <chakra.form
              shadow={`${pathname === "/add-inventory" && "base"}`}
              rounded={[null, "md"]}
              overflow={{ sm: "hidden" }}
              pb={5}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Stack
                px={4}
                py={5}
                p={[null, 6]}
                bg="white"
                _dark={{ bg: "#141517" }}
                spacing={6}
              >
                <SimpleGrid columns={6} spacing={6}>
                  <FormControl as={GridItem} colSpan={6}>
                    <FormLabel
                      htmlFor="inventory-name"
                      fontSize={{ base: "sm", md: "md" }}
                      fontWeight="semibold"
                    >
                      Inventory Name
                    </FormLabel>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      autoComplete="street-address"
                      mt={1}
                      focusBorderColor="brand.400"
                      shadow="sm"
                      size={{ base: "sm", md: "md" }}
                      w="full"
                      rounded="md"
                      readOnly={pathname !== "/add-inventory"}
                      {...register("name", { required: true })}
                      defaultValue={singleInventory?.name}
                    />
                  </FormControl>
                  {pathname === "/add-inventory" && (
                    <>
                      <FormControl as={GridItem} colSpan={[6, 3]}>
                        <FormLabel
                          htmlFor="godwon-id"
                          fontSize={{ base: "sm", md: "md" }}
                          fontWeight="semibold"
                        >
                          Godown
                        </FormLabel>
                        {/* <Input
                          type="number"
                          name="godwon-id"
                          id="godwon-id"
                          autoComplete="given-name"
                          mt={1}
                          focusBorderColor="brand.400"
                          shadow="sm"
                          size={{ base: "sm", md: "md" }}
                          w="full"
                          rounded="md"
                          {...register("godownId", {
                            required: true,
                            valueAsNumber: true,
                          })}
                        /> */}
                        <Select
                          placeholder="Select Godwon"
                          {...register("godownId", {
                            required: true,
                            valueAsNumber: true,
                          })}
                        >
                          {godwonData?.map((godwon, i) => (
                            <option key={i} value={godwon?.id}>
                              {godwon?.name}
                            </option>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl as={GridItem} colSpan={[6, 3]}>
                        <FormLabel
                          htmlFor="category-id"
                          fontSize={{ base: "sm", md: "md" }}
                          fontWeight="semibold"
                        >
                          Category
                        </FormLabel>
                        {/* <Input
                          type="number"
                          name="category-id"
                          id="category-id"
                          autoComplete="family-name"
                          mt={1}
                          focusBorderColor="brand.400"
                          shadow="sm"
                          size={{ base: "sm", md: "md" }}
                          w="full"
                          rounded="md"
                          {...register("categoryId", {
                            required: true,
                            valueAsNumber: true,
                          })}
                        /> */}
                        <Select
                          placeholder="Select Category"
                          {...register("categoryId", {
                            required: true,
                            valueAsNumber: true,
                          })}
                        >
                          {categories?.map((category, i) => (
                            <option key={i} value={category?.id}>
                              {category?.name}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </>
                  )}
                  <FormControl as={GridItem} colSpan={[6, 3]}>
                    <FormLabel
                      htmlFor="brand"
                      fontSize={{ base: "sm", md: "md" }}
                      fontWeight="semibold"
                    >
                      Brand
                    </FormLabel>
                    <Input
                      type="text"
                      name="brand"
                      id="brand"
                      autoComplete="family-name"
                      mt={1}
                      focusBorderColor="brand.400"
                      shadow="sm"
                      size={{ base: "sm", md: "md" }}
                      w="full"
                      rounded="md"
                      {...register("brand", { required: true })}
                      // value={brand}
                      defaultValue={singleInventory?.brand}
                      // onChange={(e) => setBrand(e.target.value)}
                    />
                  </FormControl>
                  <FormControl as={GridItem} colSpan={[6, 3]}>
                    <FormLabel
                      htmlFor="batch-no"
                      fontSize={{ base: "sm", md: "md" }}
                      fontWeight="semibold"
                    >
                      Batch No.
                    </FormLabel>
                    <Input
                      type="text"
                      name="batch-no"
                      id="batch-no"
                      autoComplete="family-name"
                      mt={1}
                      focusBorderColor="brand.400"
                      shadow="sm"
                      size={{ base: "sm", md: "md" }}
                      w="full"
                      rounded="md"
                      {...register("batchNo", {
                        required: true,
                      })}
                      defaultValue={singleInventory?.batchNo}
                    />
                  </FormControl>

                  <FormControl as={GridItem} colSpan={[6, 3]}>
                    <FormLabel
                      htmlFor="total-quantity"
                      fontSize={{ base: "sm", md: "md" }}
                      fontWeight="semibold"
                    >
                      Total Quantity
                    </FormLabel>
                    <Input
                      type="number"
                      name="total-quantity"
                      id="total-quantity"
                      autoComplete="email"
                      mt={1}
                      focusBorderColor="brand.400"
                      shadow="sm"
                      size={{ base: "sm", md: "md" }}
                      w="full"
                      rounded="md"
                      {...register("totalQuantity", {
                        required: true,
                        valueAsNumber: true,
                      })}
                      defaultValue={singleInventory?.totalQuantity}
                    />
                  </FormControl>

                  <FormControl as={GridItem} colSpan={[6, 3]}>
                    <FormLabel
                      htmlFor="available-stock"
                      fontSize={{ base: "sm", md: "md" }}
                      fontWeight="semibold"
                    >
                      Available Stock
                    </FormLabel>
                    <Input
                      type="number"
                      name="available-stock"
                      id="available-stock"
                      autoComplete="email"
                      mt={1}
                      focusBorderColor="brand.400"
                      shadow="sm"
                      size={{ base: "sm", md: "md" }}
                      w="full"
                      rounded="md"
                      {...register("availableStock", {
                        required: true,
                        valueAsNumber: true,
                      })}
                      defaultValue={singleInventory?.availableStock}
                    />
                  </FormControl>
                  <FormControl as={GridItem} colSpan={[6, 3]}>
                    <FormLabel
                      htmlFor="holding-stock"
                      fontSize={{ base: "sm", md: "md" }}
                      fontWeight="semibold"
                    >
                      Holding Stock
                    </FormLabel>
                    <Input
                      type="number"
                      name="holding-stock"
                      id="holding-stock"
                      autoComplete="email"
                      mt={1}
                      focusBorderColor="brand.400"
                      shadow="sm"
                      size={{ base: "sm", md: "md" }}
                      w="full"
                      rounded="md"
                      {...register("holdingStock", {
                        required: true,
                        valueAsNumber: true,
                      })}
                      defaultValue={singleInventory?.holdingStock}
                    />
                  </FormControl>
                  <FormControl as={GridItem} colSpan={[6, 3]}>
                    <FormLabel
                      htmlFor="breakage-stock"
                      fontSize={{ base: "sm", md: "md" }}
                      fontWeight="semibold"
                    >
                      Breakage Stock
                    </FormLabel>
                    <Input
                      type="number"
                      name="breakage-stock"
                      id="breakage-stock"
                      autoComplete="email"
                      mt={1}
                      focusBorderColor="brand.400"
                      shadow="sm"
                      size={{ base: "sm", md: "md" }}
                      w="full"
                      rounded="md"
                      {...register("breakageStock", {
                        required: true,
                        valueAsNumber: true,
                      })}
                      defaultValue={singleInventory?.breakageStock}
                    />
                  </FormControl>

                  {pathname === "/add-inventory" && (
                    <FormControl as={GridItem} colSpan={[6, 6]}>
                      <FormLabel
                        fontSize={{ base: "sm", md: "md" }}
                        fontWeight={"semibold"}
                      >
                        Product Photo
                      </FormLabel>
                      <Flex
                        mt={1}
                        justify="center"
                        px={6}
                        pt={5}
                        pb={6}
                        borderWidth={2}
                        _dark={{ color: "gray.500" }}
                        borderStyle="dashed"
                        rounded="md"
                      >
                        <Stack spacing={1} textAlign="center">
                          <Flex
                            fontSize={{ base: "sm", md: "md" }}
                            color="gray.600"
                            _dark={{ color: "gray.400" }}
                            alignItems="baseline"
                          >
                            <chakra.label
                              htmlFor="file-upload"
                              cursor="pointer"
                              rounded="md"
                              fontSize="md"
                              color="brand.600"
                              _dark={{ color: "brand.200" }}
                              pos="relative"
                              fontWeight={"semibold"}
                              _hover={{
                                color: "brand.400",
                                _dark: { color: "brand.300" },
                              }}
                            >
                              <Icon
                                mx="auto"
                                boxsize={12}
                                color="gray.400"
                                _dark={{ color: "gray.500" }}
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 48 48"
                                aria-hidden="true"
                                display={"block"}
                              >
                                <path
                                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </Icon>
                              {imgPath ? (
                                <span>{imgPath.name}</span>
                              ) : (
                                <span>Upload a photo</span>
                              )}
                              <VisuallyHidden>
                                <input
                                  id="file-upload"
                                  name="file-upload"
                                  type="file"
                                  onChange={handleImgPath}
                                />
                              </VisuallyHidden>
                            </chakra.label>
                          </Flex>
                        </Stack>
                      </Flex>
                    </FormControl>
                  )}
                </SimpleGrid>
              </Stack>
              <Box
                px={{ base: 4, sm: 6 }}
                py={3}
                bg="gray.50"
                textAlign="right"
              >
                <Button
                  type="submit"
                  color="gray.100"
                  // _focus={{ shadow: "" }}
                  fontWeight="semibold"
                  bg={"green.800"}
                  borderWidth={2}
                  borderColor={"transparent"}
                  _hover={{
                    bg: "transparent",
                    borderColor: "green.800",
                    color: "green.800",
                  }}
                  size={{ base: "sm", md: "lg", lg: "lg" }}
                >
                  Submit
                </Button>
              </Box>
            </chakra.form>
          </GridItem>
        </>
      </Box>
    </Box>
  );
};
