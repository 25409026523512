import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import { useRef } from "react";

export const ConfirmationModal = ({
  text,
  onClose,
  isOpen,
  setDeleteId,
  deleteInventory,
}) => {
  //   const { isOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay onClick={() => setDeleteId(null)} />

        <AlertDialogContent>
          <AlertDialogHeader>Delete Inventory</AlertDialogHeader>
          <AlertDialogCloseButton onClick={() => setDeleteId(null)} />
          <AlertDialogBody>
            Are you sure you want to delete {text} inventory?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose();
                setDeleteId(null);
              }}
            >
              Cancel
            </Button>
            <Button colorScheme="red" ml={3} onClick={() => deleteInventory()}>
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
