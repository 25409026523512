import axios from "axios";
import React, { createContext, useState } from "react";
import { useQuery } from "react-query";

export const DataStoreContext = createContext();

const host = process.env.REACT_APP_HOST;
const DataProvider = ({ children }) => {
  const [access, setAccess] = useState();
  const role = sessionStorage.getItem("status")?.split(",")?.[1];
  const [verifiedUser, setVerifiedUser] = useState(role);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [selectedPrintedInventory, setSelectedPrintedInventory] = useState([]);

  // getting access condition
  const { data: accessCon = [] } = useQuery({
    queryKey: ["access"],
    queryFn: async () => {
      try {
        const { data } = await axios.get(`${host}/access`);
        if (data.length === 0) {
          setAccess(false);
        }
        setAccess(data?.[0]?.access);
      } catch (error) {
        console.log(error);
      }
    },
  });

  const { data: godwonData = [] } = useQuery({
    queryKey: ["godwon"],
    queryFn: async () => {
      try {
        const { data } = await axios.get(`${host}/godwon`);
        if (!access && verifiedUser !== "admin") {
          return;
        }
        return data;
      } catch (error) {
        console.log(error);
      }
    },
  });

  const { data: categories = [] } = useQuery({
    queryKey: ["category"],
    queryFn: async () => {
      try {
        const { data } = await axios.get(`${host}/category`);
        if (!access && verifiedUser !== "admin") {
          return;
        }
        return data;
      } catch (error) {
        console.log(error);
      }
    },
  });
  const dataStore = {
    godwonData,
    categories,
    access,
    setAccess,
    setVerifiedUser,
    verifiedUser,
    host,
    page,
    setPage,
    limit,
    setSelectedPrintedInventory,
    selectedPrintedInventory,
  };

  return (
    <DataStoreContext.Provider value={dataStore}>
      {children}
    </DataStoreContext.Provider>
  );
};

export default DataProvider;
