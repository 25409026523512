export const getPrintInfo = (printingInventoryValues, stocksData) => {
  let printInfo = {
    details: printingInventoryValues?.map((item) => {
      if (item.availableStock < stocksData[item.id]) {
        return alert(`${item.name} quantity is not in stock`);
      }
      return {
        name: item?.name,
        brand: item?.brand,
        qty: stocksData[item?.id],
        type: stocksData[`productNo-${item?.id}-type`],
      };
    }),
    vehicleNumber: stocksData?.vehicleNo,
    remarks: stocksData?.remarks,
  };
  return printInfo;
};
