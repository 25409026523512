import { AddGodwon } from "./AddGodwon/AddGodwon";
import { GodwonTable } from "./GodwonTable/GodwonTable";

export const Godwon = () => {
  return (
    <>
      <AddGodwon />
      <GodwonTable />
    </>
  );
};
