import { Box, Flex } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import ManageAccess from "../ManageAccess/ManageAccess";
import { NavItem } from "../NavItem/NavItem";

const links = [
  { id: 1, title: "All Inventory", url: "/" },
  { id: 2, title: "Add Inventory", url: "add-inventory" },
  { id: 3, title: "Category", url: "category" },
  { id: 4, title: "Godown", url: "godwon" },
  { id: 5, title: "History", url: "history" },
];
export const SidebarContent = (props) => {
  return (
    <Box
      as="nav"
      flexDir={"row"}
      justifyContent={"space-between"}
      top="0"
      left="0"
      zIndex="sticky"
      h="full"
      pb="10"
      overflowX="hidden"
      overflowY="auto"
      bg="white"
      _dark={{ bg: "gray.800" }}
      border
      color="inherit"
      w="full"
      {...props}
    >
      <Flex
        direction={{ sm: "column", md: "row" }}
        flexDir={"column"}
        as="nav"
        fontSize="sm"
        color="gray.600"
        aria-label="Main Navigation"
      >
        {links?.map((link) => (
          <NavItem
            key={link?.id}
            fontSize={{ base: "sm", md: "md" }}
            as={Link}
            href={link?.url}
            onClose={props.onClose}
          >
            {link?.title}
          </NavItem>
        ))}
      </Flex>
      {props.verifiedUser === "admin" && (
        <ManageAccess customDisplay={{ base: "none", md: "inline-flex" }} />
      )}
    </Box>
  );
};
