import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import PrimaryButton from "../Components/PrimaryButton";
import DataNotFound from "./DataNotFound";

const timeConvert = (date) => {
  const parsedDate = new Date(date);
  const convertedDate = parsedDate.toLocaleString();
  return convertedDate;
};
const DataTable2 = ({ header, data }) => {
  return (
    <>
      {data.length !== 0 ? (
        <TableContainer>
          <Table variant="simple">
            <Thead bg={"gray.800"}>
              <Tr>
                {header.map((title, i) => (
                  <Th
                    key={i}
                    color={"gray.100"}
                    py={3}
                    textTransform={"capitalize"}
                    //   fontSize={"14px"}
                  >
                    {title}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {data.map((items, i) => (
                <Tr key={i}>
                  {/* {Object.keys(items).map((itemKey, i) => (
                    <Td key={i} textTransform={"capitalize"}>
                      {items[itemKey]}
                    </Td>
                  ))} */}
                  <Td>{items?.id}</Td>
                  {items?.name && <Td>{items?.name}</Td>}
                  <Td>{timeConvert(items?.createdDate)}</Td>
                  {header?.some((v) => v === "action") && (
                    <Td>
                      <Link to={`/history/${items?.id}`}>
                        <PrimaryButton>View Details</PrimaryButton>
                      </Link>
                    </Td>
                  )}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <DataNotFound />
      )}
    </>
  );
};

export default DataTable2;
