import axios from "axios";

export const sendUpdatedStockInv = (
  host,
  printingInventoryValues,
  stocksData
) => {
  printingInventoryValues.map((item) => {
    if (item.availableStock < stocksData[item.id]) {
      return console.log(`${item.name} quantity is not in stock`);
    } else {
      const remainStockInv = {
        ...item,
        availableStock: item.availableStock - stocksData[item.id],
      };
      const updateStockOnInv = async () => {
        await axios.patch(
          `${host}/inventory/${remainStockInv?.id}`,
          remainStockInv,
          {
            headers: { "Content-type": "application/json; charset=UTF-8" },
          }
        );
      };
      updateStockOnInv();
      return remainStockInv;
    }
  });
};
