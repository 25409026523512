import { AddCategory } from "./AddCategory/AddCategory";
import { CategoryTable } from "./CategoryTable/CategoryTable";

export const Category = () => {
  return (
    <>
      <AddCategory />
      <CategoryTable />
    </>
  );
};
