export const updatableInventory = (inventory, singleInventory) => {
  const {
    brand,
    batchNo,
    totalQuantity,
    availableStock,
    holdingStock,
    breakageStock,
  } = inventory;
  let updateableInventory = {
    name: singleInventory?.name,
    brand: brand ? brand : singleInventory.brandNo,
    batchNo: batchNo ? batchNo : singleInventory.batchNo,
    totalQuantity: totalQuantity
      ? totalQuantity
      : singleInventory.totalQuantity,
    availableStock: availableStock
      ? availableStock
      : singleInventory.availableStock,
    holdingStock: holdingStock ? holdingStock : singleInventory.holdingStock,
    breakageStock: breakageStock
      ? breakageStock
      : singleInventory.breakageStock,
  };
  return updateableInventory;
};
