import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Input,
  InputGroup,
  Select,
} from "@chakra-ui/react";
import axios from "axios";
import { useContext, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { InventoryDataTable } from "../../../Components/InventoryDataTable";
import Pagination from "../../../Components/Pagination";
import { PrintButton } from "../../../Components/PrintButton";
import SectionTitle from "../../../Components/SectionTitle";
import { DataStoreContext } from "../../../Context/DataProvider";

const header = [
  "",
  "image",
  "inventory name",
  "batch no.",
  "brand",
  "quantity",
  "available stock",
  "holding stock",
  "breakage stock",
  "godwon id",
  "category name",
  "actions",
];

export const InventoryTable = () => {
  const {
    access,
    verifiedUser,
    host,
    page,
    limit,
    setPage,
    setSelectedPrintedInventory,
  } = useContext(DataStoreContext);
  const [queryText, setQueryText] = useState();
  const [filterText, setFilterText] = useState();
  const [printValue, setPrintValue] = useState([]);
  const [count, setCount] = useState(0);

  const navigate = useNavigate();
  const {
    data: inventory = [],
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ["inventory", page, limit],
    queryFn: async () => {
      try {
        const { data } = await axios.get(
          `${host}/inventory?page=${page}&limit=${limit}`
        );
        if (!access && verifiedUser !== "admin") {
          return;
        }
        setCount(data?.total);
        return data?.data;
      } catch (error) {
        console.log(error);
      }
    },
  });

  const clearCheckHandler = () => {
    setPrintValue([]);
  };

  // print inventory
  const printHandler = () => {
    setSelectedPrintedInventory((prev) => [...prev, ...printValue]);
    const itemUrl = printValue.map(
      (item, i) =>
        `id=${item.id}&name${i + 1}=${item.name
          .toLowerCase()
          .split(" ")
          .join("-")}`
    );
    const itemUrls = itemUrl.join("&");

    // Closing all access
    if (verifiedUser !== "admin") {
      return alert("Access Denied!");
    }

    if (itemUrl.length > 0) {
      navigate(`/print-inventory?${itemUrls}`);
    } else {
      return alert("Please! Select minimum one inventory");
    }
  };

  const pageNo = Math.ceil(count / limit);

  const handlePageChange = (page) => {
    setPage(page);
    refetch();
  };

  return (
    <>
      <SectionTitle>All Inventory</SectionTitle>
      <Flex
        justifyContent={"space-between"}
        my={5}
        flexDir={{ base: "column", md: "row" }}
        rowGap={4}
      >
        <HStack>
          <Select
            name="country"
            autoComplete="country"
            placeholder="Filter Inventory"
            focusBorderColor="brand.400"
            shadow="sm"
            size={{ base: "sm", sm: "md" }}
            rounded="md"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          >
            <option value="name">Name</option>
            <option value="brand">Brand</option>
          </Select>
          <Flex py={{ sm: 5, md: 0 }} align="center">
            <InputGroup w={{ base: 60, md: 72 }}>
              <Input
                placeholder="Search for Inventory..."
                size={{ base: "sm", sm: "md" }}
                focusBorderColor="brand.400"
                onChange={(e) => setQueryText(e.target.value)}
              />
            </InputGroup>
          </Flex>
        </HStack>
        <HStack justifyContent={"space-between"}>
          <Button
            size={{ base: "sm", md: "md", lg: "md" }}
            border="2px"
            borderColor="green.500"
            onClick={clearCheckHandler}
          >
            Uncheck All{" "}
          </Button>
          <PrintButton handler={printHandler}>Print</PrintButton>
        </HStack>
      </Flex>
      {isLoading ? (
        <Box py={10} p={5}>
          <Heading textAlign="center">Loading...</Heading>
        </Box>
      ) : (
        <>
          <InventoryDataTable
            header={header}
            data={inventory}
            refetch={refetch}
            filterText={filterText}
            queryText={queryText}
            setPrintValue={setPrintValue}
            printValue={printValue}
          />
          {inventory?.length !== 0 && (
            <Flex gap={2} justify={"center"} mt={5}>
              <Pagination
                currentPage={page}
                totalPages={pageNo}
                onPageChange={handlePageChange}
              />
            </Flex>
          )}
        </>
      )}
    </>
  );
};
