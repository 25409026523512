import { Box, Flex, Heading } from "@chakra-ui/react";
import React from "react";
import { TbDatabaseOff } from "react-icons/tb";
const DataNotFound = () => {
  return (
    <>
      <Box textAlign="center" py={10} px={6}>
        <Box display="inline-block">
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bg={"red.500"}
            rounded={"50px"}
            w={"55px"}
            h={"55px"}
            textAlign="center"
          >
            <TbDatabaseOff boxsize={"20px"} color={"white"} />
          </Flex>
        </Box>
        <Heading as="h2" size="xl" mt={6} mb={2}>
          Data Not Found
        </Heading>
      </Box>
    </>
  );
};

export default DataNotFound;
