import { Box, Button, chakra, FormControl, Input } from "@chakra-ui/react";
import axios from "axios";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import SectionTitle from "../../../../Components/SectionTitle";
import { DataStoreContext } from "../../../../Context/DataProvider";

export const AddGodwon = () => {
  const { host, verifiedUser } = useContext(DataStoreContext);
  const {
    register,
    handleSubmit,
    reset,
    // formState: { errors },
  } = useForm();
  const onSubmit = async (godwon) => {
    // Closing all access
    if (verifiedUser !== "admin") {
      return alert("Access Denied!");
    }
    // store data on database
    try {
      const { data } = await axios.post(`${host}/godwon`, godwon);
      if (data.name) {
        alert("Godwon Added");
      }
    } catch (error) {
      console.log(error);
    }
    reset();
  };
  return (
    <>
      <SectionTitle>Add New Godown</SectionTitle>
      <chakra.form
        shadow="base"
        rounded={[null, "md"]}
        overflow={{ sm: "hidden" }}
        p={{ base: 2, md: 5 }}
        mt={5}
        display={"flex"}
        alignItems={"center"}
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormControl>
          <Input
            type="text"
            name="first_name"
            id="first_name"
            autoComplete="given-name"
            mt={1}
            focusBorderColor="brand.400"
            shadow="sm"
            size="md"
            w="full"
            rounded="md"
            placeholder="Godwon name..."
            {...register("name", { required: true })}
          />
        </FormControl>
        <Box
          px={{ base: 4, sm: 6 }}
          bg="gray.50"
          _dark={{ bg: "#121212" }}
          textAlign="right"
        >
          <Button
            type="submit"
            colorScheme="red"
            _focus={{ shadow: "" }}
            fontWeight="md"
          >
            Save
          </Button>
        </Box>
      </chakra.form>
    </>
  );
};
