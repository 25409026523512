import { Button, Flex, Stack } from "@chakra-ui/react";
import React, { useContext } from "react";
import { DataStoreContext } from "../../../../Context/DataProvider";
import { accessUpdate } from "../../../../utilities/accessUpdate";

const ManageAccess = ({ customDisplay }) => {
  const { access, setAccess, host } = useContext(DataStoreContext);
  const handleAcces = async (condition) => {
    accessUpdate(host, condition, setAccess);
  };
  return (
    <>
      <Flex justify="center" mb={1} display={customDisplay}>
        <Stack
          direction="row"
          textAlign="center"
          rounded="md"
          borderWidth={1}
          borderColor={"gray.200"}
        >
          <Button
            colorScheme="green"
            variant={access ? "solid" : "ghost"}
            onClick={() => handleAcces(true)}
            px={6}
            size={{ base: "sm", md: "md" }}
          >
            Active
          </Button>
          <Button
            colorScheme="red"
            variant={!access ? "solid" : "ghost"}
            onClick={() => handleAcces(false)}
            px={6}
            size={{ base: "sm", md: "md" }}
          >
            Deactive
          </Button>
        </Stack>
      </Flex>
    </>
  );
};

export default ManageAccess;
