import { Button } from "@chakra-ui/react";

export const PrintButton = ({
  children,
  handler,
  style,
  disabledCondition,
}) => {
  return (
    <Button
      transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
      border="1px"
      borderRadius="md"
      fontWeight="semibold"
      bg="#f5f6f7"
      borderColor="#ccd0d5"
      color="#4b4f56"
      size={{ base: "sm", md: "md", lg: "md" }}
      _hover={{ bg: "#ebedf0" }}
      _active={{
        bg: "#dddfe2",
        transform: "scale(0.98)",
        borderColor: "#bec3c9",
      }}
      _focus={{
        boxShadow:
          "0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
      }}
      sx={style}
      onClick={handler}
      isDisabled={disabledCondition}
    >
      {children}
    </Button>
  );
};
