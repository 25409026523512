import axios from "axios";

export const updateData = async (
  host,
  updatedData,
  path,
  dataId,
  successText
) => {
  const res = await axios.patch(`${host}/${path}/${dataId}`, updatedData, {
    headers: { "Content-type": "application/json; charset=UTF-8" },
  });
  if (res.statusText === "OK") {
    alert(successText);
  }
};
