import { Flex, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export const NavItem = (props) => {
  const { icon, children, href, onClose, ...rest } = props;
  return (
    <Flex
      align="center"
      cursor="pointer"
      color="inherit"
      _dark={{ color: "gray.400" }}
      _hover={{
        bg: "gray.100",
        _dark: { bg: "gray.900" },
        color: "gray.900",
        borderRadius: "5px",
      }}
      role="group"
      fontWeight="semibold"
      transition=".15s ease"
      {...rest}
      as={Link}
      to={href}
      onClick={onClose}
    >
      <Text px="4" pl="4" py="3">
        {children}
      </Text>
    </Flex>
  );
};
