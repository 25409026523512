import {
  ButtonGroup,
  IconButton,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import { useContext, useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import { BsFillTrashFill } from "react-icons/bs";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import { DataStoreContext } from "../Context/DataProvider";
import { ConfirmationModal } from "./ConfirmationModal";
import DataNotFound from "./DataNotFound";
import { FormModal } from "./FormModal";

export const InventoryDataTable = ({
  header,
  data,
  queryText,
  filterText,
  setPrintValue,
  printValue,
}) => {
  const { categories, godwonData, host, verifiedUser } =
    useContext(DataStoreContext);
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [deleteId, setDeleteId] = useState(null);
  const [singleInventory, setSingleInventory] = useState(null);

  // delete inventory handler
  const deleteInventory = () => {
    // Closing all access
    if (verifiedUser !== "admin") {
      return alert("Access Denied!");
    }
    axios.delete(`${host}/inventory/${deleteId}`).then((res) => {
      if (res.statusText === "OK") {
        alert("Inventory Successfully Deleted");
      }
    });
    setDeleteId(null);
  };

  // getting single inventory data
  const getSingleInventory = async (inventoryId) => {
    // Closing all access
    if (verifiedUser !== "admin") {
      return alert("Access Denied!");
    }
    try {
      const { data } = await axios.get(`${host}/inventory/${inventoryId}`);
      setSingleInventory(data);
    } catch (error) {
      console.log(error);
    }
  };

  // filter inventory
  const inventoryFilter = (filterItem) => {
    if (filterText === "name" && queryText) {
      return filterItem.name.toLowerCase().includes(queryText.toLowerCase());
    } else if (filterText === "brand" && queryText) {
      return filterItem.brand.toLowerCase().includes(queryText.toLowerCase());
    }
    return filterItem;
  };

  // match data finding
  const dataMatching = (
    matchableData,
    items,
    matchValueName,
    expectValueName
  ) => {
    const match = matchableData.find(
      (value) => value?.id === items[matchValueName]
    )
      ? matchableData.find((value) => value?.id === items[matchValueName])[
          expectValueName
        ]
      : "None";
    return match;
  };

  return (
    <>
      {data.length !== 0 ? (
        <TableContainer>
          <Table variant="simple">
            <Thead bg={"gray.800"}>
              <Tr>
                {header.map((title, i) => (
                  <Th
                    key={i}
                    color={"gray.100"}
                    py={3}
                    textTransform={"capitalize"}
                    //   fontSize={"14px"}
                  >
                    {title}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {data
                ?.filter((filterItem) => {
                  return inventoryFilter(filterItem);
                })
                ?.map((items, i) => (
                  <Tr key={i}>
                    <Td>
                      <input
                        type="checkbox"
                        // colorScheme="green"
                        // defaultChecked={printValue?.includes(items.id)}
                        checked={printValue?.some((v) => v.id === items.id)}
                        onChange={() => {
                          printValue?.some((v) => v.id === items.id)
                            ? setPrintValue(
                                printValue.filter(
                                  (value) => value.id !== items.id
                                )
                              )
                            : setPrintValue([
                                ...printValue,
                                data.find((value) => value.id === items.id),
                              ]);
                        }}
                      ></input>
                    </Td>
                    <Td>
                      <PhotoProvider>
                        <PhotoView
                          src={
                            items?.inventoryImage
                              ? items?.inventoryImage
                              : "https://t3.ftcdn.net/jpg/04/84/88/76/360_F_484887682_Mx57wpHG4lKrPAG0y7Q8Q7bJ952J3TTO.jpg"
                          }
                        >
                          <Image
                            w={"50px"}
                            src={
                              items?.inventoryImage
                                ? items?.inventoryImage
                                : "https://t3.ftcdn.net/jpg/04/84/88/76/360_F_484887682_Mx57wpHG4lKrPAG0y7Q8Q7bJ952J3TTO.jpg"
                            }
                          ></Image>
                        </PhotoView>
                      </PhotoProvider>
                    </Td>
                    <Td>{items?.name}</Td>
                    <Td>{items?.batchNo}</Td>
                    <Td>{items?.brand}</Td>
                    <Td>{items?.totalQuantity}</Td>
                    <Td bg={"#609966"}>{items?.availableStock}</Td>
                    <Td bg={"#F7C04A"}>{items?.holdingStock}</Td>
                    <Td bg={"#F16767"}>{items?.breakageStock}</Td>
                    <Td>{dataMatching(godwonData, items, "godownId", "id")}</Td>
                    <Td>
                      {dataMatching(categories, items, "categoryId", "name")}
                    </Td>
                    <Td>
                      <ButtonGroup variant="solid" size="sm" spacing={3}>
                        <IconButton
                          colorScheme="green"
                          icon={<AiFillEdit />}
                          aria-label="Edit"
                          onClick={() => {
                            onOpen();
                            getSingleInventory(items.id);
                          }}
                        />
                        <IconButton
                          colorScheme="red"
                          variant="outline"
                          icon={<BsFillTrashFill />}
                          aria-label="Delete"
                          onClick={() => {
                            onOpen();
                            setDeleteId(items?.id);
                          }}
                        />
                      </ButtonGroup>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <DataNotFound />
      )}
      {deleteId && (
        <ConfirmationModal
          id={"alert"}
          isOpen={isOpen}
          setDeleteId={setDeleteId}
          onClose={onClose}
          deleteInventory={deleteInventory}
        />
      )}
      {singleInventory && (
        <FormModal
          id={"form"}
          isOpen={isOpen}
          onClose={onClose}
          singleInventory={singleInventory}
          setSingleInventory={setSingleInventory}
        />
      )}
    </>
  );
};
