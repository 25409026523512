import { createBrowserRouter } from "react-router-dom";
import { AddInventory } from "../Page/Dashboard/AddInventory/AddInventory";
import { Category } from "../Page/Dashboard/Categories/Category";
import { Dashboard } from "../Page/Dashboard/Dashboard";
import { Godwon } from "../Page/Dashboard/Godwon";
import History from "../Page/Dashboard/History/History";
import HistoryDetails from "../Page/Dashboard/HistoryDetails/HistoryDetails";
import { InventoryTable } from "../Page/Dashboard/InventoryTable/InventoryTable";
import PrintInventory from "../Page/Dashboard/PrintInventory/PrintInventory";
import PrintPage from "../Page/Dashboard/PrintInventory/PrintPage/PrintPage";
import Login from "../Page/Login/Login";
import NotFound from "../Page/NotFound/NotFound";
import { PrivateRouter } from "../PrivateRoute/PrivateRoute";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <PrivateRouter>
        <Dashboard />
      </PrivateRouter>
    ),
    errorElement: <NotFound />,
    children: [
      { index: true, element: <InventoryTable /> },
      { path: "/add-inventory", element: <AddInventory /> },
      {
        path: "/category",
        element: <Category />,
      },
      { path: "/godwon", element: <Godwon /> },
      { path: "/history", element: <History /> },
      { path: "/history/:historyId", element: <HistoryDetails /> },
      { path: "/print-inventory", element: <PrintInventory /> },
      { path: "/print-inventory/print-page", element: <PrintPage /> },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
]);
