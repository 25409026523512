import { Button } from "@chakra-ui/react";
import React from "react";

function PrimaryButton({
  action,
  disabledCondition,
  children,
  btnType = "submit",
}) {
  return (
    <>
      <Button
        type={btnType}
        color="gray.100"
        fontWeight="semibold"
        bg={"green.800"}
        borderWidth={2}
        borderColor={"transparent"}
        _hover={{
          bg: "transparent",
          borderColor: "green.800",
          color: "green.800",
        }}
        size={{ base: "sm", md: "md", lg: "md" }}
        onClick={action}
        isDisabled={disabledCondition}
      >
        {children}
      </Button>
    </>
  );
}

export default PrimaryButton;
