import React from "react";
import { InventoryStock } from "./InventoryStock/InventoryStock";

const PrintInventory = () => {
  return (
    <>
      <InventoryStock />
    </>
  );
};

export default PrintInventory;
