import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  chakra,
} from "@chakra-ui/react";
import axios from "axios";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { DataStoreContext } from "../../Context/DataProvider";

export default function Login() {
  const { setVerifiedUser, host } = useContext(DataStoreContext);
  const location = useLocation();
  const navigate = useNavigate();
  const from = location?.state?.from?.pathname || "/";
  const {
    register,
    handleSubmit,
    // formState: { errors },
  } = useForm();
  const onSubmit = async (inputedUserData) => {
    try {
      const { data } = await axios.get(`${host}/user`);
      const currentUser = data.find(
        (user) =>
          user.email === inputedUserData.email &&
          user.password === inputedUserData.password
      );
      if (!currentUser) {
        alert("Login Failed. Please! check your email and password");
      } else {
        sessionStorage.setItem("status", [true, currentUser.role]);
        setVerifiedUser(sessionStorage.getItem("status")?.split(",")?.[1]);
      }
      navigate(from, { replace: true });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"} bg={"gray.50"}>
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"}>Sign in to your account</Heading>
        </Stack>
        <Box rounded={"lg"} bg={"white"} boxShadow={"lg"} p={8}>
          <chakra.form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={4}>
              <FormControl id="email">
                <FormLabel>Email address</FormLabel>
                <Input
                  type="email"
                  {...register("email", { required: true })}
                />
              </FormControl>
              <FormControl id="password">
                <FormLabel>Password</FormLabel>
                <Input
                  type="password"
                  {...register("password", { required: true })}
                />
              </FormControl>
              <Stack spacing={10}>
                <Button
                  type="submit"
                  bg={"blue.400"}
                  color={"white"}
                  _hover={{
                    bg: "blue.500",
                  }}
                >
                  Sign in
                </Button>
              </Stack>
            </Stack>
          </chakra.form>
        </Box>
      </Stack>
    </Flex>
  );
}
