import { Navigate, useLocation } from "react-router-dom";

export const PrivateRouter = ({ children }) => {
  const verified = sessionStorage.getItem("status")?.split(",");
  const location = useLocation();
  if (verified?.[0]) {
    return children;
  }
  return <Navigate to="/login" state={{ from: location }} replace></Navigate>;
};
