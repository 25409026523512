import axios from "axios";
import React, { useContext } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { DataStoreContext } from "../../../Context/DataProvider";
import PrintPage from "../PrintInventory/PrintPage/PrintPage";
const header = ["id", "date", "action"];
function HistoryDetails() {
  const { historyId } = useParams();
  const { access, host, verifiedUser } = useContext(DataStoreContext);
  const { data: productHistory = [] } = useQuery({
    queryKey: ["product-history", "history-details", historyId],
    queryFn: async () => {
      try {
        const { data } = await axios.get(
          `${host}/product-history/history-details/${historyId}`
        );
        if (!access && verifiedUser !== "admin") {
          return;
        }
        return data;
      } catch (error) {
        console.log(error);
      }
    },
  });
  const { data: historyInfo = {} } = useQuery({
    queryKey: ["product-history", historyId],
    queryFn: async () => {
      try {
        const { data } = await axios.get(
          `${host}/product-history/${historyId}`
        );
        if (!access && verifiedUser !== "admin") {
          return;
        }
        return data;
      } catch (error) {
        console.log(error);
      }
    },
  });

  const historyDetails = {
    id: historyId,
    details: productHistory,
    remarks: historyInfo?.remarks,
    vehicleNumber: historyInfo?.vehicleNumber,
  };

  return (
    <>
      <PrintPage historyDetails={historyDetails} />
    </>
  );
}

export default HistoryDetails;
