import { Box, Flex, Heading } from "@chakra-ui/react";
import axios from "axios";
import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import DataTable2 from "../../../Components/DataTable2";
import Pagination from "../../../Components/Pagination";
import SectionTitle from "../../../Components/SectionTitle";
import { DataStoreContext } from "../../../Context/DataProvider";
const header = ["id", "date", "action"];
function History() {
  const { access, host, verifiedUser } = useContext(DataStoreContext);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const {
    data: history = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["product-history", page, limit],
    queryFn: async () => {
      try {
        const { data } = await axios.get(
          `${host}/product-history?page=${page}&limit=${limit}`
        );
        if (!access && verifiedUser !== "admin") {
          return;
        }

        setCount(data?.total);
        return data?.data;
      } catch (error) {
        console.log(error);
      }
    },
  });

  const pageNo = Math.ceil(count / limit);

  const handlePageChange = (page) => {
    setPage(page);
    refetch();
  };
  return (
    <>
      <SectionTitle style={{ my: 5, mt: 10 }}>All History</SectionTitle>
      {isLoading ? (
        <Box py={10} p={5}>
          <Heading textAlign="center">Loading...</Heading>
        </Box>
      ) : (
        <>
          <DataTable2 header={header} data={history} />
          <Flex gap={2} justify={"center"} mt={5}>
            <Pagination
              currentPage={page}
              totalPages={pageNo}
              onPageChange={handlePageChange}
            />
          </Flex>
        </>
      )}
    </>
  );
}

export default History;
