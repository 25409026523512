import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useRef } from "react";
import { AddInventory } from "../Page/Dashboard/AddInventory/AddInventory";

export const FormModal = ({
  isOpen,
  onClose,
  setFormModal,
  singleInventory,
  setSingleInventory,
}) => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  return (
    <Box>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size={{ base: "md", md: "2xl" }}
        scrollBehavior={"inside"}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Inventory</ModalHeader>
          <ModalCloseButton onClick={() => setSingleInventory(null)} />
          <ModalBody pb={6}>
            <AddInventory
              singleInventory={singleInventory}
              setSingleInventory={setSingleInventory}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
