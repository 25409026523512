import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { useContext } from "react";
import { FiMenu } from "react-icons/fi";
import { Outlet } from "react-router-dom";
import { DataStoreContext } from "../../Context/DataProvider";
import ManageAccess from "./Sidebar/ManageAccess/ManageAccess";
import { SidebarContent } from "./Sidebar/SidebarContent/SidebarContent";

export const Dashboard = ({ children }) => {
  const { verifiedUser } = useContext(DataStoreContext);
  const sidebar = useDisclosure();
  return (
    <Box w={{ sm: "98%", md: "97%", lg: "95%" }} margin="auto" my="5">
      <Box as="section" minH="100vh">
        <SidebarContent
          display={{ base: "none", md: "flex" }}
          verifiedUser={verifiedUser}
          onClose={sidebar.onClose}
        />
        <Drawer
          isOpen={sidebar.isOpen}
          onClose={sidebar.onClose}
          placement="left"
        >
          <DrawerOverlay />
          <DrawerContent>
            <SidebarContent
              w="full"
              borderRight="none"
              verifiedUser={verifiedUser}
              onClose={sidebar.onClose}
            />
          </DrawerContent>
        </Drawer>
        <Box ml={{ base: 0, md: 0 }} transition=".3s ease">
          <Flex
            as="header"
            align="center"
            justify="space-between"
            w="full"
            px="4"
            bg="white"
            _dark={{ bg: "gray.800" }}
            color="inherit"
            h={{ sm: "14", md: 0 }}
          >
            <IconButton
              aria-label="Menu"
              display={{ base: "inline-flex", md: "none" }}
              onClick={sidebar.onOpen}
              icon={<FiMenu />}
              size={{ base: "sm", sm: "md" }}
            />

            {verifiedUser === "admin" && (
              <ManageAccess
                customDisplay={{ base: "inline-flex", md: "none" }}
              />
            )}
          </Flex>

          <Box as="main" p="4" mt={{ base: 2, md: 0 }}>
            {children}
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
