import axios from "axios";

export const accessUpdate = async (host, condition, setAccess) => {
  try {
    const { data } = await axios.get(`${host}/access`);
    if (data.length === 0) {
      await axios.post(
        `${host}/access`,
        {
          access: condition,
        },
        {
          headers: { "Content-type": "application/json; charset=UTF-8" },
        }
      );
    } else {
      const res = await axios.patch(
        `${host}/access/${data[0].id}`,
        { access: condition },
        {
          headers: { "Content-type": "application/json; charset=UTF-8" },
        }
      );
      setAccess(res.data.access);
    }
  } catch (error) {
    console.log(error);
  }
};
