import { Button, Text } from "@chakra-ui/react";
import React from "react";

function Pagination({ currentPage, totalPages, onPageChange }) {
  const maxVisiblePages = 3;
  const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
  const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

  const pages = [];
  for (let page = startPage; page <= endPage; page++) {
    pages.push(
      <Button
        key={page}
        onClick={() => onPageChange(page)}
        _hover={{ bg: "gray.700", color: "gray.100" }}
        sx={
          currentPage === page
            ? { bg: "gray.700", color: "gray.100" }
            : { bg: "transparent", color: "gray.700" }
        }
      >
        {page}
      </Button>
    );
  }

  return (
    <>
      {startPage > 1 && (
        <>
          <Button
            _hover={{ bg: "gray.700", color: "gray.100" }}
            bg={"transparent"}
            onClick={() => onPageChange(1)}
          >
            1
          </Button>
          {startPage > 2 && (
            <Text fontWeight={"bold"} fontSize={"lg"} as={"span"}>
              ...
            </Text>
          )}
        </>
      )}
      {pages}
      {endPage < totalPages && (
        <>
          {endPage < totalPages - 1 && (
            <Text fontWeight={"bold"} fontSize={"lg"} as={"span"}>
              ...
            </Text>
          )}
          <Button
            _hover={{ bg: "gray.700", color: "gray.100" }}
            bg={"transparent"}
            onClick={() => onPageChange(totalPages)}
          >
            {totalPages}
          </Button>
        </>
      )}
    </>
  );
}

export default Pagination;
