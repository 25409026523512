import {
  Box,
  Flex,
  Heading,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useContext, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import PrimaryButton from "../../../../Components/PrimaryButton";
import { PrintButton } from "../../../../Components/PrintButton";
import { DataStoreContext } from "../../../../Context/DataProvider";

const tableHeader = ["name", "brand", "stock"];
const PrintPage = ({ historyDetails = {} }) => {
  const { host } = useContext(DataStoreContext);
  const [success, setSuccess] = useState(false);
  const [historyId, setHistoryId] = useState(null);
  const getPrintInfo = JSON.parse(sessionStorage.getItem("printInfo"));
  const { details, vehicleNumber, remarks } = historyDetails?.id
    ? historyDetails
    : getPrintInfo;
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const totalQuantity = details?.reduce((acc, item) => acc + item?.qty, 0);

  // save print able products info on database for history page
  const handleSaveProduct = async () => {
    try {
      const res = await axios?.post(`${host}/product-history`, getPrintInfo);
      if (res?.status === 201) {
        setHistoryId(res?.data?.historyId);
        setSuccess(true);
        alert("Saved on history page");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const productsHistoryId = historyId || historyDetails?.id;

  return (
    <>
      <Box
        textAlign={"center"}
        as={Flex}
        alignItems={"center"}
        justifyContent={"space-between"}
        mb={10}
        gap={5}
        flexDir={{ base: "column", sm: "column", md: "row" }}
      >
        <Heading textAlign={"center"}>
          {historyDetails?.id
            ? "Product History Details"
            : "Print Inventory Page"}
        </Heading>
        <Flex gap={4}>
          {!historyDetails?.id && (
            <PrimaryButton
              action={handleSaveProduct}
              disabledCondition={success}
            >
              {success ? "Saved" : "Save"}
            </PrimaryButton>
          )}
          <PrintButton
            handler={handlePrint}
            disabledCondition={!success && !historyDetails?.id && true}
          >
            Print This Out!
          </PrintButton>
        </Flex>
      </Box>
      <Box display={"none"}>
        <Box ref={componentRef}>
          <Text fontWeight={"medium"} fontSize={"lg"} mt={5} ml={6}>
            ID: {productsHistoryId}
          </Text>
          <PrintTable
            details={details}
            remarks={remarks}
            vehicleNumber={vehicleNumber}
            totalQuantity={totalQuantity}
          />
        </Box>
      </Box>
      <Box mt={5}>
        <PrintTable
          details={details}
          remarks={remarks}
          vehicleNumber={vehicleNumber}
          totalQuantity={totalQuantity}
        />
      </Box>
    </>
  );
};

export default PrintPage;

const PrintTable = ({ details, remarks, vehicleNumber, totalQuantity }) => {
  return (
    <>
      <TableContainer>
        <Table variant="simple">
          <TableCaption fontSize={"lg"} pb={0}>
            <Flex justifyContent={"center"} gap={3}>
              <Flex flexDir={"column"} textAlign={"left"} gap={2}>
                <Text display={"inline-block"} fontWeight={"semibold"}>
                  Total Quantity.
                </Text>
                <Text display={"inline-block"} fontWeight={"semibold"}>
                  Vehicle No.{" "}
                </Text>
                <Text display={"inline-block"} fontWeight={"semibold"}>
                  Remarks.{" "}
                </Text>
                <Text display={"inline-block"} fontWeight={"semibold"}>
                  Date.{" "}
                </Text>
              </Flex>
              <Flex flexDir={"column"} gap={2} textAlign={"left"}>
                <Text display={"inline-block"} fontWeight={"normal"}>
                  {totalQuantity}
                </Text>
                <Text display={"inline-block"} fontWeight={"normal"}>
                  {vehicleNumber}
                </Text>
                <Text display={"inline-block"} fontWeight={"normal"}>
                  {remarks}
                </Text>
                <Text display={"inline-block"} fontWeight={"normal"}>
                  {" "}
                </Text>
              </Flex>
            </Flex>
          </TableCaption>
          {/* <TableCaption fontSize={"lg"} pt={0}>
            Remarks.{" "}
            <Text display={"inline-block"} fontWeight={"normal"}>
              {remarks}
            </Text>
          </TableCaption>
          <TableCaption fontSize={"lg"} pt={0}>
            Date.{" "}
          </TableCaption> */}
          <Thead>
            <Tr>
              {tableHeader.map((item, i) => (
                <Th key={i}>{item}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {details?.map((item, i) => (
              <React.Fragment key={i}>
                {item && (
                  <Tr>
                    <Td>{item?.name}</Td>
                    <Td>{item?.brand}</Td>
                    <Td>
                      {item?.qty} {item?.type}
                    </Td>
                  </Tr>
                )}
              </React.Fragment>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};
