import axios from "axios";
import { useContext } from "react";
import { useQuery } from "react-query";
import DataTable2 from "../../../../Components/DataTable2";
import SectionTitle from "../../../../Components/SectionTitle";
import { DataStoreContext } from "../../../../Context/DataProvider";

const header = ["id", "name", "created"];

export const CategoryTable = () => {
  const { access, host, verifiedUser } = useContext(DataStoreContext);
  const { data: categories = [] } = useQuery({
    queryKey: ["category"],
    queryFn: async () => {
      try {
        const { data } = await axios.get(`${host}/category`);
        if (!access && verifiedUser !== "admin") {
          return;
        }
        return data;
      } catch (error) {
        console.log(error);
      }
    },
  });
  return (
    <>
      <SectionTitle style={{ my: 5, mt: 10 }}>All Category</SectionTitle>
      <DataTable2 header={header} data={categories} />
    </>
  );
};
